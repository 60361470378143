/** @jsx jsx */
import React from 'react';
import { jsx, Box } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { Avatar } from '@sprinklr/shared-lib';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

const ELTPanel: React.FC<{
  padding: ContainerPadding;
  pageContext: any;
  eltPeople: any;
}> = ({ padding, eltPeople }) => {
  const totalPeople = eltPeople.length;
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Container containerSx={{ pt, pb }} fullWidth={true}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: [
              '100%',
              totalPeople >= 6 && totalPeople <= 8 ? '1060px' : '1325px',
              totalPeople >= 6 && totalPeople <= 8 ? '1200px' : '1360px',
            ],
            flexWrap: 'wrap',
          }}
        >
          {eltPeople.map(person => (
            <Avatar
              key={person.name}
              titleRichText={person?.titleRichText}
              name={person.name}
              image={person.image?.gatsbyImageData}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ELTPanel;
